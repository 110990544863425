<template lang="html">
    <div class="container inputOutput">
        <top-navigation-bar
            title="One Mean"
            right-nav="reset"
            @click="doReset"
        />
        <h4 class="mb-3 text-center divider">
            What do you want to test?
        </h4>

        <button
            class="action-btn"
            @click="showImportModal"
        >
            Import Dataset
        </button>
        <div class="m-view d-flex">
            <h2>H<sub>A</sub> :  μ </h2>
            <select
                v-model="selected.value"
                class="form-select-lg mx-2"
            >
                <option
                    v-for="(option, key) in options"
                    :key="key"
                >
                    {{ option.value }}
                </option>
            </select>
            <input
                v-model="μ0"
                placeholder="?"
                class="input-single w-25 py-1"
                @keyup.enter="onSubmit()"
            >
        </div>
        <input-single
            v-model="x̄"
            :label="{text: 'Mean', mathChar: 'x̄'}"
            style-class="input-single"
            @enter-pressed="onSubmit()"
        />
        <input-single
            v-model="s"
            :label="{text: 'Std Dev', mathChar: 's'}"
            style-class="input-single"
            @enter-pressed="onSubmit()"
        />
        <input-single
            v-model="n"
            :label="{text: 'Sample Size', mathChar: 'n'}"
            style-class="input-single"
            @enter-pressed="onSubmit()"
        />

        <div class="sticky-bottom m-3 mt-5">
            <button
                class="action-btn btn-block"
                block
                @click="onSubmit()"
            >
                Calculate
            </button>
        </div>

        <SelectDatasetModal
            ref="modalImport"
            @select="importDataset"
        />
        <modal
            ref="modalOutput"
            title="One Mean - Result"
            modal-type="hypothesis"
        >
            <template #formula>
                <div class="m-view">
                    <h2 class="mr-5">
                        H<sub>0</sub> : μ  = {{ μ0 }}
                    </h2>
                </div>
                <div class="m-view d-flex ml-4">
                    <h2>H<sub>A</sub> : μ {{ selected.value }}</h2>
                    <input
                        v-model="μ0"
                        placeholder="?"
                        class="input-single h3 w-25 py-1 mx-1"
                        @keyup.enter="onSubmit()"
                    >
                </div>
            </template>

            <template #image>
                <div class="m-view d-flex justify-content-start">
                    <img
                        v-if="n < 30"
                        class="formulaImage-med"
                        src="@/assets/HTformulaImgs/OneT.jpg"
                    >
                    <img
                        v-else
                        class="formulaImage-med"
                        src="@/assets/HTformulaImgs/OneZ.jpg"
                    >
                    <h3 class="formulaImage-text">
                        = {{ z }}
                    </h3>
                    <h3
                        v-if="df"
                        class="formulaImage-df pl-3"
                    >
                        df = {{ df }}
                    </h3>
                </div>
            </template>
            <template #graph>
                <h4 class="mb-3 text-center">
                    p-Value = {{ notEqualPdisplay }}P({{ charDisplay }} {{ symbolPdisplay }} {{ z }}) = {{ prob }}
                </h4>
                <highcharts
                    class="m-auto"
                    :options="hypothGraph.chartOptions"
                />
            </template>
            <template #conclusion>
                <h3 class="mb-3 text-center">
                    Conclusion:
                </h3>
                <h5 class="mb-3 text-center">
                    There is a {{ probDisplay }}% chance of concluding <br> μ {{ selected.value }} {{ μ0 }} and being wrong
                </h5>
            </template>
        </modal>
    </div>
</template>

<script>
import InputSingle from '@/components/input-single.vue'
import Modal from '@/components/modal.vue'
import SelectDatasetModal from '@/components/select-dataset-modal.vue'
import TopNavigationBar from '@/components/top-navigation-bar.vue'
import normalData from '@/js/distributions/normal-data.js'
import hypothesisData from '@/js/hypothesis/hypothesis-data.js'
import { clearData } from '@/js/lib/input-check.js'
import sData from '@/js/summarizeData/summarize-data.js'
import localStore from '@/store/localStore.js'
import { Chart } from 'highcharts-vue'
import _ from 'lodash'

export default {
    name: 'OneMeanInput',
    components: {
        TopNavigationBar,
        highcharts: Chart,
        InputSingle,
        Modal,
        SelectDatasetModal,
    },
    data () {
        return {
            allData: {},
            computeType: 'hypothesis',
            μ0: '', // Assumed value for μ in H𝗈
            x̄: '', // Sample Mean
            s: '', // Sample Standard Deviation
            n: '', // Sample Size
            t: '',
            z: '',
            df: '',
            prob: '',
            graphData: [],
            options: [
                { value: '<' },
                { value: '>' },
                { value: '≠' },
            ],
            selected: { value: '≠' }
        }
    },
    computed: {
        notEqualPdisplay () {
            const output = this.selected.value === '≠' ? '2' : ''
            return output
        },
        symbolPdisplay () {
            var { selected, z } = this
            if (selected.value !== '≠') {
                return selected.value
            } else {
                const symbol = z >= 0 ? '>' : '<'
                return symbol
            }
        },
        charDisplay () {
            return this.n < 30 ? 't' : 'z'
        },
        probDisplay () {
            var prob = _.get(this, 'prob', '')
            const output = Number((prob * 100).toFixed(2))
            return output
        },
        hypothGraph () {
            return hypothesisData.graph(this.graphData)
        }
    },
    beforeMount () {
        var store = localStore.getStore('hypothesis-data')
        if (store) {
            _.assign(this, { allData: store })
            var formulaStore = _.get(store, 'OneMean', '')
            if (formulaStore) {
                _.assign(this, formulaStore.input)
            }
        }
    },
    methods: {
        doReset () {
            _.assign(this, clearData(this, ['μ0', 'x̄', 's', 'n']))
        },
        showImportModal () {
            this.$refs.modalImport.show()
        },
        showOutputModal () {
            this.$refs.modalOutput.show()
        },
        importDataset (dataset) {
            const data = sData.calculateDataCheck(dataset.items)
            if (!_.isEmpty(data.errors)) {
                alert(_.head(data.errors))
            } else {
                this.x̄ = data.output.mean
                this.s = data.output.stdDev
                this.n = data.output.sampleSize
            }
        },
        onSubmit () {
            var { allData } = this
            const output = hypothesisData.calculateOneMean(this)
            if (!output) {
                console.log('Calculation Error')
            } else {
                _.assign(this, output.data)
                const graphOutput = normalData.calculateGraphData(this, 0, 1)
                _.set(this, 'graphData', graphOutput)

                _.set(allData, 'OneMean', { input: output.input, output: output.data })
                localStore.setStoreItem('hypothesis-data', allData)
                this.showOutputModal()
            }
        },
    },
}
</script>
